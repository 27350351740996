<template lang="pug">
.wf-table.full-box
  DataTable(
    v-if="showTable"
    ref="dataTable"
    serverSide
    resource="/customer/list"
    :filterFields="filterFields"
    :columns="columns"
    :toolbarButtonList="toolbarButtonList"
    :operateButtonList="operateButtonList"
    :defaultSortBy="[{prop: 'customerCode', order: 'desc'}]"
    :cellClassNameFunc="customerStatusClassFunc"
    @rowDblClick="handleRowDblClick")
  DrawerForm(
    v-model="formData"
    :formSubType="formSubType"
    :drawer.sync="drawer"
    @submitFinish="getData"
  )
</template>

<script>
import { mapActions } from 'vuex'
import cloneDeep from 'lodash/cloneDeep'
import { tableColumns } from './config'
import DrawerForm from './components/DrawerForm.vue'
import { classHandleFunc } from '@/utils/common.js'

export default {
  name: 'CustomerManage',
  components: { DrawerForm },
  data () {
    const actionRights = this.$getPageActions([
      { name: 'add', url: '/file/customerManage/add' },
      { name: 'edit', url: '/file/customerManage/edit' },
      { name: 'stop', url: '/file/customerManage/stop' },
      { name: 'start', url: '/file/customerManage/start' },
      { name: 'detail', url: '/file/customerManage/detail' }
    ])
    return {
      // 侧拉表单是否展开
      drawer: false,
      // 表格尺寸
      customerStatusClassFunc: classHandleFunc('enable'),
      // 表格筛选表单配置
      filterFields: [{
        name: 'enable',
        label: '启停状态',
        form: {
          tag: 'select',
          clearable: true,
          relation: 'enable',
          inputWidth: '270px'
        }
      }, {
        name: 'keyword',
        label: '关键词',
        form: {
          tag: 'input',
          like: true,
          placeholder: '编号/姓名/证件号码/联系电话',
          inputWidth: '270px'
        }
      }, {
        name: 'address',
        label: '联系地址',
        form: {
          tag: 'input',
          like: true,
          inputWidth: '270px'
        }
      }, {
        name: 'orgId',
        label: '水厂',
        form: {
          tag: 'select',
          multiple: true,
          collapseTags: true,
          clearable: true,
          filterable: true,
          relation: 'waterWorks',
          inputWidth: '270px'
        }
      }, {
        name: 'createTime',
        label: '建档时间',
        form: {
          tag: 'date',
          dateType: 'daterange',
          inputWidth: '270px'
        }
      }],
      // 表格列配置
      showTable: false,
      columns: tableColumns,
      // 表格数据
      tableData: [],
      toolbarButtonList: actionRights.add ? [
        {
          label: '建档',
          name: 'add',
          type: 'primary',
          func: () => {
            this.formSubType = 'add'
            this.formData = {
              idType: '0',
              idNum: '',
              createTime: this.$day().valueOf()
            }
            this.drawer = true
          }
        }
      ] : [],
      operateButtonList: [{
        label: '编辑',
        name: 'edit',
        type: 'text',
        textType: 'primary',
        showabled: actionRights.edit,
        func: ({ data }) => {
          data.idAttchIds = cloneDeep(data.idAtts)
          this.formData = cloneDeep(data)
          this.formSubType = 'edit'
          this.drawer = true
        }
      }, {
        label: '启用',
        name: 'start',
        type: 'text',
        textType: 'primary',
        showabled: (data) => actionRights.start && data.enable === 'STOP',
        func: this.handleStart
      }, {
        label: '停用',
        name: 'stop',
        type: 'text',
        textType: 'danger',
        showabled: (data) => actionRights.stop && data.enable === 'START',
        func: this.handleStop
      }, {
        label: '详情',
        name: 'info',
        type: 'text',
        textType: 'primary',
        showabled: actionRights.detail,
        func: ({ data }) => {
          const push = {
            name: 'customerDetail',
            params: {
              customerId: data.id
            }
          }
          window.globalEvent.emit('addTag', {
            push,
            tag: { title: `${data.customerCode}用户详情` }
          })
          // data.idAttchIds = cloneDeep(data.idAtts)
          // this.formData = cloneDeep(data)
          // this.formSubType = 'info'
          // this.drawer = true
        }
      }],
      formData: {},
      formSubType: ''
    }
  },
  methods: {
    ...mapActions('relation', ['getRelations']),
    renderRelation () {
      this.getRelations(['idType', 'waterWorks', 'enable'])
        .then(res => {
          this.$renderRelationColumns(res, [this.columns, this.filterFields])
          this.$nextTick(() => { this.showTable = true })
        })
    },
    getData () {
      this.$refs.dataTable.reRenderTableData()
    },
    handleRowDblClick (row) {
      const detailButton = this.operateButtonList[3]
      detailButton.showabled && detailButton.func({ data: row })
    },
    startOrStopHandleFunc ({ keyword, sourceKey, data }) {
      return new Promise((resolve, reject) => {
        this.$confirm(`是否确认${keyword}`, '提示')
          .then(() => {
            this.$patch({
              url: `/customer/${sourceKey}/${data.id}`
            }).then(res => {
              if (!res) {
                reject(new Error('error'))
                return
              }
              resolve('success')
              this.$message.success(`${keyword}成功!`)
              this.getData()
            }).catch(reject)
          })
          .catch(reject)
      })
    },
    handleStart ({ data }) {
      return this.startOrStopHandleFunc({
        keyword: '启用',
        sourceKey: 'start',
        data
      })
    },
    handleStop ({ data }) {
      return this.startOrStopHandleFunc({
        keyword: '停用',
        sourceKey: 'stop',
        data
      })
    }
  },
  created () {
    this.renderRelation()
  }
}
</script>
<style lang="sass" scoped>
.wf-table
  display: flex
  flex: 1
  flex-direction: column
  background-color: #eee
  width: 100%
  height: 100%
.drawerForm
  padding: 8px
</style>
